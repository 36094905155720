export const BLOG_OVERVIEW = 'blog';
export const BLOG_CATEGORY_OVERVIEW = 'blogCategoryOverview';
export const BLOG_DETAIL = 'blogPages';
export const EVENT_CATEGORY_OVERVIEW = 'eventCategoryOverview';
export const EVENT_OVERVIEW = 'events';
export const EVENT_DETAIL = 'eventPages';
export const HOME = 'home';
export const PAGE = 'page';
export const SEARCH = 'search';

export const ENTRY_TYPE_RESTAURANT = 'page_restaurant_Entry';
export const ENTRY_TYPE_MAIN = 'page_mainPage_Entry';
export const ENTRY_TYPE_LANDING = 'page_landingpage_Entry';
export const ENTRY_TYPE_HOTEL = 'page_hotel_Entry';
